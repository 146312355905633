import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore"; 
import { db } from "../firebase";

import EmbassyRunnerGame from "../components/events/Embassy_Runner";
import { useEffect, useState } from "react";

const EventRoom = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const inputId = searchParams.get('gameId');

  const navigate = useNavigate();

  const [userData, setUserData] = useState();
  const [gameData, setGameData] = useState();
  const [currentGameId, setCurrentGameId] = useState(inputId);
  const [allGamesArray, setAllGamesArray] = useState([]);
  const [reloadGame, setReloadGame] = useState(false);
  const [startedGame, setStartedGame] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if(currentGameId !== null){
        const eventId = currentGameId.substring(0,5);
        const userId = currentGameId.substring(6, 16);

        let eventName = "";
        if(eventId == "chd25"){
          eventName = "embassy"
        }
    
        setUserData(userId);

        const gameRef = doc(db, "events", eventName, "games", userId);
        const gameSnap = await getDoc(gameRef);
  
        if(gameSnap !== null){
          setGameData(gameSnap.data());
  
          if(!startedGame){
            if(eventName === "embassy"){
              navigate("/events/childrenday2025?gameId=" + userId);
              setStartedGame(true);
            }
          }
        }
      }
      else{
        navigate("/gameroom");
      }
    };
  
    fetchData();
  }, [currentGameId, reloadGame, location]);

  const handleCardClick = (gameId) =>{
    setReloadGame(!reloadGame)
    setCurrentGameId(gameId);
  }

  const clearData = () =>{
    setUserData(undefined);
    setGameData(undefined);
    setCurrentGameId(null);
    setStartedGame(false);
  }

  return (
      <Routes>
        <Route path="/" element={
          <div></div>
        } 
        />
        <Route path="/childrenday2025" element={
            <EmbassyRunnerGame
              userData={userData}
              gameData={gameData}
              clearData={clearData}
            />} 
        />
      </Routes>
  );
}

export default EventRoom;