import React, { Fragment } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CopyToClipboard from 'react-copy-to-clipboard';

import "../../css/GameRoom.css"

const EmbassyRunnerGame = ({userData, gameData}) => {
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const inputId = searchParams.get('gameId');

  const [playerTextures, setPlayerTextures] = useState(null);
  const [texturesLoaded, setTexturesLoaded] = useState(false);
  const [playerAudio, setPlayerAudio] = useState(null);
  const [audioLoaded, setAudioLoaded] = useState(false);
  const [unloading, setUnloading] = useState(false);
  const [showCopy, setShowCopy] = useState(false);
  const [allLoaded, setAllLoaded] = useState(false);

  const { unityProvider, sendMessage, addEventListener, removeEventListener, isLoaded, loadingProgression, unload } =
    useUnityContext({    
      loaderUrl: "/assets/games/embassy_runner/Embassy_RunnerV1.1.1.loader.js",
      dataUrl: "/assets/games/embassy_runner/Embassy_RunnerV1.1.1.data",
      frameworkUrl: "/assets/games/embassy_runner/Embassy_RunnerV1.1.1.framework.js",
      codeUrl: "/assets/games/embassy_runner/Embassy_RunnerV1.1.1.wasm",
  });

  useEffect(()=>{
    if(userData == null || gameData == null || gameData == undefined || userData == undefined){
      if(inputId !== null && inputId !== undefined){
        console.log("NAV TO " + inputId);
        window.location.href = "https://www.littlelot.studio/events?gameId=chd25_" + inputId;
        //window.location.href = "https://localhost:3000/events?gameId=chd25_" + inputId;
      }
      else{
        navigate("/gameroom")
      }
    }
  },[gameData])

  useEffect(()=>{
    if(gameData !== null && gameData !== undefined && isLoaded){
      /*if(gameData.gameDesign["gameicon"] !== null){
        sendMessage("LoadingCanvas", "SetLoadingData", "gameicon," + gameData.gameDesign["gameicon"]);
      }
  
      if(gameData["gameName"] !== null){
        sendMessage("LoadingCanvas", "SetLoadingData", "gameName," + gameData["gameName"]);
      }
  
      if(gameData["userName"] !== null){
        sendMessage("LoadingCanvas", "SetLoadingData", "userName," + gameData["userName"]);
      }*/

      const gameDesign = gameData.gameDesign;
      const filteredDesigns = Object.keys(gameDesign)
      .filter((key) => gameDesign[key].includes("https://") && !key.endsWith("sfx"))
      .reduce((obj, key) => {
        obj[key] = gameDesign[key];
        return obj;
      }, {});

      sendMessage("LoadingCanvas", "SetLoadingAmount", Object.keys(filteredDesigns).length);
      console.log("loadingAmount: " + Object.keys(filteredDesigns).length);
    }
  },[gameData, isLoaded])

  // URL
  useEffect(() => {
    async function sendTextureUrlsToUnity(textureUrls) {
      try {
        const baseTexturesObj = {};
        textureUrls.forEach((textureUrl) => {
          const keys = Object.keys(gameData.gameDesign).filter(
            (k) => gameData.gameDesign[k] === textureUrl
          );
          
          keys.forEach((key) => {
            if (!baseTexturesObj[key]) {
              baseTexturesObj[key] = textureUrl;
            }
          });
        });
        
        setPlayerTextures(baseTexturesObj);
        setTexturesLoaded(true);
      } catch (error) {
        console.error("Error sending texture URLs:", error);
      }
    }

    if (gameData !== undefined) {
      const gameDesign = gameData.gameDesign;
      const filteredDesigns = Object.keys(gameDesign)
        .filter((key) => gameDesign[key].includes("https://") && !key.endsWith("sfx"))
        .reduce((obj, key) => {
          obj[key] = gameDesign[key];
          return obj;
        }, {});

      sendTextureUrlsToUnity(Object.values(filteredDesigns));
    }
  }, [gameData]);

  useEffect(() => {  
    if (gameData !== undefined) {
      const gameDesign = gameData.gameDesign;
      const filteredDesigns = Object.fromEntries(
        Object.entries(gameDesign)
          .filter(([key, _]) => key.substring(key.length - 3) === "sfx")
      );
    
      setPlayerAudio(filteredDesigns);
      setAudioLoaded(true);
    }
  }, [gameData]);

  useEffect(() => {
    if (isLoaded && texturesLoaded && audioLoaded) {
      const delay = 200; // Adjust delay as needed (in milliseconds)

      const sendMessagesWithDelay = (items, messageType, callback) => {
        let index = 0;
        const keys = Object.keys(items);

        const sendNextMessage = () => {
          if (index < keys.length) {
            const key = keys[index];
            if (typeof items[key] === "string") {
              console.log("EmbassyGenerator", messageType, key + "," + items[key]);
              sendMessage("EmbassyGenerator", messageType, key + "," + items[key]);
            }
            else if (Number.isInteger(items[key])) { // Check if it's an integer
              console.log("EmbassyGenerator", messageType, key + "," + items[key].toString());
              sendMessage("EmbassyGenerator", messageType, key + "," + items[key].toString()); // Convert to string
            }  
            index++;
            setTimeout(sendNextMessage, delay);
          } else {
            if (callback) callback();
          }
        };

        sendNextMessage();
      };

      sendMessagesWithDelay(gameData.gameDesign, "SetGameTexture", () =>{
        setTimeout(() => {
          setAllLoaded(true);
          //sendMessage("RunnerGameController", "SetGameReady", "true");
        }, delay);
      })
    }
  }, [isLoaded, texturesLoaded, audioLoaded, sendMessage]);

  const handleGameOver = useCallback(() => {
    console.log("GameOver");
  }, []);

  useEffect(() => {
    addEventListener("GameOverClick", handleGameOver);
    return () => {
      removeEventListener("GameOverClick", handleGameOver);
    };
  }, [addEventListener, removeEventListener, handleGameOver]);


  useEffect(() => {
    async function unloadUnity() {
      await unload();
      setUnloading(false);
      window.location.href = "https://www.littlelot.studio/gameroom"
    }

    if (unloading) {
      unloadUnity();
    }
  }, [unloading, unload, navigate]);

  const handleBackClick = () =>{
    setUnloading(true);
  }

  const handleShareClick = () =>{
    if(showCopy)
      return;

    setShowCopy(true);
    setTimeout(()=>{
      setShowCopy(false);
    }, 3000);
  }

  const containerRef = useRef(null);

  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: "100%",
    overflow: 'hidden',
    marginTop: '50px',
    maxWidth: '1080px',
    margin: '0 auto',
    zIndex:"50"
  };

  const unityStyle = {
    position: 'absolute',
    width: 'auto',  // Auto width
    height: '80dvh', // 80% of viewport height
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    aspectRatio: '9 / 16',  // Aspect ratio
  };

  const bodyStyle = {
    margin: '0',
    padding: '0',
    height: '110vh',  // full viewport height
    overflow: 'hidden',  // prevent scrolling
    position: "relative",
    fontFamily: "Quicksand, sans-serif",
    fontWeight: "800"
  };

  return (
    <Fragment>
      <div style={bodyStyle}>
        <img 
            src="/assets/games/gameroom/cardboard.png"
            style={{position:"absolute", top:"0", height: "100%", width:"100%", zIndex:"-10"}}
        />

        <img 
            src="/assets/games/gameroom/littlelot_logo.png"
            style={{position:"absolute", width:"50px", bottom:"20px", left:"20px"}}
        />

        <img 
            src="/assets/games/gameroom/gamecreator_logo.png"
            style={{position:"absolute", width:"50px", bottom:"20px", right:"20px"}}
        />

        <div 
            style={{position:"absolute", width:"100%", height:"10vh", bottom:"100px", display:"flex", justifyContent:"center"}}
        >
          <img 
            src="/assets/games/embassy_runner/makegametext.png"
          />

          <img 
            src="/assets/games/embassy_runner/makegamebutton.png"
            style={{cursor:"pointer", zIndex:"100"}}
            onClick={()=>window.location.href = "https://lin.ee/2l7fFk5"}
          />
        </div>

        <img 
            src="/assets/games/gameroom/gr_star.png"
            style={{position:"absolute", width:"20px", top:"1%", left:"15%", transform:"rotate(12deg)"}}
        />

        <img 
            src="/assets/games/gameroom/gr_star.png"
            style={{position:"absolute", width:"20px", top:"5%", left:"80%", transform:"rotate(30deg)"}}
        />

        <img 
            src="/assets/games/gameroom/gr_star.png"
            style={{position:"absolute", width:"20px", top:"30%", left:"6%", transform:"rotate(-5deg)"}}
        />

        <img 
            src="/assets/games/gameroom/gr_star.png"
            style={{position:"absolute", width:"20px", top:"60%", left:"96%", transform:"rotate(-5deg)"}}
        />
                <img 
            src="/assets/games/gameroom/gr_star.png"
            style={{position:"absolute", width:"20px", top:"50%", left:"27%", transform:"rotate(-5deg)"}}
        />

        <div 
          className="center-child" 
          style={{position:"absolute", width:"30px", height:"30px", top:"2%", left:"10px", backgroundColor:"black", borderRadius:"50%", margin:"0px 5px", cursor:"pointer", zIndex:"60"}}
          onClick={()=>handleBackClick()}
        >
            <img src="/assets/icon/back_icon_white.png" style={{width:"60%"}}/>
        </div>

        <img className="game-howto" src="/assets/games/runner/runner_howto.png"/>

        {userData !== undefined &&
          <CopyToClipboard text={"https://www.littlelot.studio/events?gameId=" + "chd25_" + userData} onCopy={handleShareClick}>
            <div 
              className="center-child" 
              style={{position:"absolute", width:"30px", height:"30px", top:"2%", right:"10px", backgroundColor:"black", borderRadius:"50%", margin:"0px 5px", cursor:"pointer", zIndex:"60"}}
              onClick={()=>handleShareClick()}
            >
                <img src="/assets/icon/share_icon_white.png" style={{width:"60%"}}/>
            </div>
          </CopyToClipboard>
        }

        {gameData !== undefined &&
            <div style={{position:"absolute", display:"flex", width:"100%", height:"65px", top:"5px", justifyContent:"center", alignItems:"center"}}>
              <div style={{textAlign:"center", overflowWrap:"break-word", lineHeight:"25px", width:"70%", fontSize:"25px"}}>{gameData.gameName}</div>
            </div>
        }

        {showCopy &&
          <div style={{zIndex:"200", width:"200px", height:"50px", paddingTop:"5px", backgroundColor:"white", opacity:"0.8", position:"absolute", top:"50%", left:"50%", textAlign:"center", transform:"translate(-50%,-50%)"}}>{"Link Copied \n Let's Share Your Game!!"}</div>
        }

        {!allLoaded &&
          <div style={{position:"absolute", display:"flex", justifyContent:"center", alignItems:"center", width:"150px", height: "45px", top:"50%", left:"50%", zIndex:"200"}}>
            <img src="/assets/icon/textbg_yellow.png" style={{position:"absolute", width:"100%", zIndex:"201"}}/>
            <p style={{color:"black", zIndex:"202", position:"absolute"}}>Loading... {Math.round(loadingProgression * 100) === 100 ? 99 : Math.round(loadingProgression * 100)}%</p>
          </div>
        }
        <div ref={containerRef} style={containerStyle}>
          <Unity 
            unityProvider={unityProvider} 
            style={unityStyle} 
          />
        </div>
      </div>
    </Fragment>
  );
}

export default EmbassyRunnerGame;